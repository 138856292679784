<template>
  <v-card
    style="min-height: 100vh"
    :class="showVoucher ? 'bg-light no-scrolling' : 'bg-light'"
  >
    <v-app-bar app fixed outlined dense color="#ffffff" elevation="0.5">
      <v-icon @click="close()">mdi-close</v-icon>
      <v-toolbar-title class="text-caption font-weight-6 ml-3">
        <div class="font-weight-bold">
          Checkout <v-icon small>mdi-cart-check</v-icon>
        </div>
        <small class="position-relative" style="top: -3px">{{
          profile.restaurant_name
        }}</small>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-avatar
        color="light-blue"
        size="32"
        rounded
        class="text-body-2 font-weight-bold white--text"
        v-if="isDinein"
      >{{ tableNo || tableDetails.tableNo }}</v-avatar>
    </v-app-bar>
    <v-sheet class="sheet-spacer mb-2"></v-sheet>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <v-alert
          v-if="error"
          class="alert text-body-2 p-2 mt-6"
          type="error"
          transition="fade-transition"
          dense
          >{{ error }}</v-alert
        >
        <v-sheet class="receiver-details p-3 pb-2" v-if="isLoggedIn || !isDinein">
          <v-card flat>
            <v-card-title 
              class="text-body-2 font-weight-6 m-0 p-0 mb-2"
              v-if="!isDinein"
            >
              Receiver Details
            </v-card-title>
            <v-card-title 
              class="text-body-2 font-weight-6 m-0 p-0 mb-2"
              v-else
            >
              Customer Details
            </v-card-title>
            <v-row>
              <v-col>
                <ValidationProvider
                  name="Name"
                  rules="required|max:30"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="receiverDetails.name"
                    class="brackettext p-0 m-0"
                    placeholder="eg. EatMol"
                    label="Name"
                    color="primary"
                    dense
                    outlined
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    :disabled="!isDelivery && !!customerName.trim()"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                  name="Contact No"
                  :rules="{ required: true, regex: /^\d{10,13}$/ }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="receiverDetails.contactNo"
                    class="brackettext p-0 m-0"
                    placeholder="eg. 601816281628"
                    label="Contact No"
                    dense
                    outlined
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    :disabled="!isDelivery && customer && isValidPhoneNo(customer.phoneNo)"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="justify-center mb-2" v-if="!isDinein">
              <v-col cols="6">
                <vc-date-picker
                  v-model="receivingDate"
                  mode="date"
                  color="primary"
                  :attributes="attrs"
                  :modelConfig="modelConfig"
                  :masks="masks"
                  :min-date="minReceivingDate"
                  :disabled-dates="disabledDates"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      placeholder="2021-06-12"
                      class="dateandtimetxt text-caption p-0 m-0"
                      v-on="inputEvents"
                      :value="inputValue"
                      append-icon="mdi-calendar-month"
                      :disabled="storeClosed"
                      label="Date"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="receivingTime"
                  placeholder="12:00 pm"
                  class="dateandtimetxt text-caption"
                  :items="availableReceivingTimes"
                  item-text="label"
                  item-value="value"
                  label="Time"
                  dense
                  outlined
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
        <v-sheet v-if="!isDinein" class="service-details p-3 pb-0 mb-3">
          <v-card v-if="isDelivery" class="pb-2" flat>
            <v-card-title class="text-body-2 font-weight-6 m-0 p-0 mb-2"
              >Delivery Details</v-card-title
            >
            <v-row>
              <v-col>
                <ValidationProvider
                  name="Address"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-autocomplete
                    v-model="address"
                    class="brackettext"
                    label="Your Location"
                    item-text="description"
                    item-value="description"
                    placeholder="Start typing to Search"
                    append-icon="mdi-map-search"
                    :items="places"
                    :loading="isLoading"
                    :search-input.sync="search"
                    :error-messages="errors"
                    :success="valid"
                    dense
                    outlined
                    hide-no-data
                    hide-selected
                    hide-details
                    no-filter
                    return-object
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            class="brackettext"
                            v-html="data.item.structured_formatting.main_text"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            class="brackettext"
                            v-html="
                              data.item.structured_formatting.secondary_text
                            "
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="deliveryAddress.location"
                  class="brackettext p-0 m-0"
                  placeholder="eg. Block 10A, 5th Floor"
                  label="Block / Floor / Unit No"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-caption">
                <v-textarea
                  rows="2"
                  v-model="instructions"
                  class="brackettext p-0 m-0"
                  placeholder="eg. Contactless delivery"
                  label="Instructions"
                  outlined
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
          <v-card v-if="isPickup" class="pb-2" flat>
            <v-card-title class="text-body-2 font-weight-6 m-0 p-0 mb-2"
              >Pickup Details</v-card-title
            >
            <v-row>
              <v-col>
                <v-textarea
                  rows="2"
                  v-model="instructions"
                  class="brackettext p-0 m-0"
                  placeholder="e.g Give me an extra spoon"
                  label="Instructions"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
        <v-sheet class="order-summary p-3 pb-0 mb-3">
          <v-card class="pb-1" flat>
            <v-card-title class="text-body-2 font-weight-6 m-0 p-0 mb-2"
              >Order Details</v-card-title
            >
            <cart-product-card
              :list="cart.products"
              class="px-2" 
              v-if="isCartUpdated"
              v-on:cart-updated="handleCartUpdated($event)" 
            ></cart-product-card>
          </v-card>
        </v-sheet>
        <v-sheet class="summary-order-total p-3 mb-3 text-caption">
          <v-row class="font-weight-6">
            <v-col cols="8">Subtotal <small class="brackettext" v-if="isMenuIncludeTax">( excl. SST )</small></v-col>
            <v-col cols="4">{{
              $n(subTotal.toFixed(2), 'currency', 'en-MY')
            }}</v-col>
          </v-row>
          <v-row v-if="packagingFee">
            <v-col cols="8">Packaging Fees</v-col>
            <v-col cols="4">{{
              $n(packagingFee, 'currency', 'en-MY')
            }}</v-col>
          </v-row>
          <v-row
            v-if="
              isDelivery &&
              cart.delivery.originalFee &&
              cart.delivery.distance > 0
            "
          >
            <v-col cols="8">
              Delivery Fees <span v-if="cart.delivery.discount > 0">(-{{ $n(cart.delivery.discount, 'currency', 'en-MY') }})</span>
            </v-col>
            <v-col cols="4">{{
              $n(cart.delivery.fee, 'currency', 'en-MY')
            }}</v-col>
          </v-row>
          <v-row v-if="cart.discount.id">
            <v-col cols="8">Discount ({{ cart.discount.percentage }}%)</v-col>
            <v-col cols="4"
              >-{{ $n(discountTotal, 'currency', 'en-MY') }}</v-col
            >
          </v-row>
          <v-row v-if="serviceCharge">
            <v-col cols="8">Service Charge ({{ profile.options.merchant_service_charges }}%)</v-col>
            <v-col cols="4">{{
              $n(serviceCharge, 'currency', 'en-MY')
            }}</v-col>
          </v-row>
          <v-row v-if="cart.totals.taxRate > 0">
            <v-col cols="8">Tax (SST {{ cart.totals.taxRate }}%)</v-col>
            <v-col cols="4">{{ $n(taxTotal, 'currency', 'en-MY') }}</v-col>
          </v-row>
          <v-row v-if="cart.voucher.id">
            <v-col cols="8">Voucher (<a class="summary-voucher-code">{{ cart.voucher.code }}</a>)</v-col>
            <v-col cols="4">-{{ $n(voucherTotal, 'currency', 'en-MY') }}</v-col>
          </v-row>
          <v-row v-if="rounding">
            <v-col cols="8">Rounding</v-col>
            <v-col cols="4">{{ $n(rounding, 'currency', 'en-MY') }}</v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="7" class="text-h6 font-weight-6"
              >Total <small class="brackettext">( incl. SST )</small></v-col
            >
            <v-col cols="5" class="text-h6 font-weight-6">{{
              $n(totalWithTax, 'currency', 'en-MY')
            }}</v-col>
          </v-row>
        </v-sheet>
        <v-sheet class="order-payment-details p-3 mb-3">
          <v-card v-if="paymentMethods" flat>
            <v-card-title class="text-body-2 font-weight-6 m-0 p-0"
              >Payment Details</v-card-title
            >
            <v-row class="mt-1">
              <v-col cols="7" class="pl-4 pr-0">
                <ValidationProvider
                  name="Payment"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-select
                    v-model="paymentMethod"
                    placeholder="Select"
                    class="text-caption"
                    :items="paymentMethods"
                    :error-messages="errors"
                    :success="valid"
                    item-text="label"
                    item-value="value"
                    hide-details
                    dense
                    outlined
                    :disabled="totalWithTax <= 0"
                  >
                    <template v-slot:selection="{ item }">
                      <v-avatar size="20">
                        <v-img
                          v-if="!['cod', 'pyc'].includes(item.value)"
                          :alt="`${item.label}`"
                          :src="
                            require(`@/assets/images/payment-method-${item.value}.png`)
                          "
                        ></v-img>
                        <v-icon v-if="item.value == 'cod'" color="primary"
                          >mdi-account-cash</v-icon
                        >
                        <v-icon v-if="item.value == 'pyc'" color="primary"
                          >mdi-cash-register</v-icon
                        >
                      </v-avatar>
                      <small
                        class="
                          ml-2
                          p-0
                          text-body-2
                          col-9
                          text-caption text-truncate
                        "
                        >{{ item.label }}</small
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <v-avatar size="30">
                        <v-img
                          v-if="!['cod', 'pyc'].includes(item.value)"
                          :alt="`${item.label}`"
                          :src="
                            require(`@/assets/images/payment-method-${item.value}.png`)
                          "
                        ></v-img>
                        <v-icon v-if="item.value == 'cod'" color="primary"
                          >mdi-account-cash</v-icon
                        >
                        <v-icon v-if="item.value == 'pyc'" color="primary"
                          >mdi-cash-register</v-icon
                        >
                      </v-avatar>
                      <small class="ml-3 text-body-2">{{ item.label }}</small>
                    </template>
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="5" class="px-4">
                <v-text-field
                  v-model="selectedVoucherCode"
                  placeholder="Voucher"
                  class="input-voucher text-caption p-0 m-0"
                  slot="activator"
                  color="primary"
                  dense
                  outlined
                  hide-details
                  :disabled="showVoucher"
                  @click="showVoucherModal()"
                >
                  <v-icon slot="prepend-inner" color="primary" class="mr-1">mdi-ticket-percent</v-icon>
                  <v-icon slot="append" v-if="selectedVoucherCode"
                    small 
                    color="grey lighten-1" 
                    class="mt-1" 
                    @click="removeVoucher()"
                  >mdi-close-circle-outline</v-icon>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
        <v-sheet class="billing-details p-3 mb-3" v-if="isDinein && !tableNo">
          <v-card flat>
            <v-card-title class="text-body-2 font-weight-6 m-0 p-0"
              >Table Details</v-card-title
            >
            <v-row class="mt-1">
              <v-col>
                <ValidationProvider
                  name="Table No"
                  :rules="{ required: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-select
                    v-model="tableDetails.tableNo"
                    class="text-caption"
                    :items="availableTableNo"
                    label="Table No"
                    dense
                    outlined
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                  ></v-select>
                  <div class="table-no-hint mt-2 px-2 red--text text--lighten-3" v-if="!error">
                    ** Sorry, we couldn't find the table no. Please select your table no here, thank you!
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
        <v-sheet class="billing-details p-3 mb-3" v-if="!isLoggedIn && !isDinein">
          <v-card flat>
            <v-card-title class="text-body-2 font-weight-6 m-0 p-0"
              >Billing Details</v-card-title
            >
            <v-row class="mt-1">
              <v-col>
                <ValidationProvider
                  name="Billing Email"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="billingDetails.email"
                    class="text-caption p-0 m-0"
                    placeholder="eg. billing@eatmol.com"
                    color="primary"
                    dense
                    outlined
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                  <div class="billing-hint mt-2 px-2 red--text text--lighten-3" v-if="errors.length == 0">
                    ** We will send your order receipt to this email.
                  </div>
                  <div class="billing-hint mt-2 px-2 red--text text--lighten-3" v-else>
                    ** Please enter a valid email address (remove any leading/trailing &lt;space&gt;)
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
        <v-sheet class="term-conditions p-3">
          <v-card flat>
            <div
              style="color: rgba(100, 100, 100, 0.8)"
              class="text-center text-caption font-weight-6"
            >
              I agree to the Terms and Conditions by<br />completing this order
            </div>
          </v-card>
        </v-sheet>
        <v-bottom-navigation
          class="bottomwidthbtn"
          fixed
          :background-color="amountDiffToCheckout !== 0 ? 'disabled' : 'primary'"
        >
          <v-btn
            type="submit"
            block
            :disabled="amountDiffToCheckout !== 0 || isLoading"
            :class="`${ amountDiffToCheckout !== 0 ? 'text-body-2 font-weight-6' : 'text-body-1 font-weight-bold' } align-self-center text-uppercase`"
          >
            <v-icon v-if="isLoading">mdi-loading</v-icon>
            <template v-else>
              <div class="white--text" v-if="!isDinein && isPreOrder()">Pre-Order</div>
              <div class="white--text" v-else>Confirm Order</div>
              <!-- <div class="white--text" v-else>Pay RM {{ totalWithTax.toFixed(2) }}</div> -->
            </template>
          </v-btn></v-bottom-navigation
        >
      </form>
    </ValidationObserver>
    <notify-update-modal :version="version" v-if="version && version.changelog && hasUpdate"></notify-update-modal>
    <notify-cart-changed-modal :type="cartChangedType" v-on:close-notify-cart-changed="closeNotifyCartChanged($event)" v-if="isCartChanged"></notify-cart-changed-modal>
    <voucher-modal 
      :visible="showVoucher" 
      :selected="selectedVoucherCode"
      v-on:close-voucher="closeVoucher($event)" 
      v-on:apply-voucher="applyVoucher($event)"
      v-if="isLoggedIn"
    ></voucher-modal>
    <i-pay88
      :key="getPaymentId()"
      v-if="isIpay88() && (order || guestOrder)"
      :order="(order || guestOrder)"
      :payment-id="getPaymentId()"
      :payment-method="paymentMethod"
    ></i-pay88>
    <Cash
      :key="getPaymentId()"
      v-if="isCash() && (order || guestOrder)"
      :order="(order || guestOrder)"
      :payment-id="getPaymentId()"
      :payment-method="paymentMethod"
    ></Cash>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import CartProductCard from '@/components/Menu/CartProductCard';
import NotifyUpdateModal from '@/components/DialogModals/NotifyUpdateModal';
import NotifyCartChangedModal from '@/components/DialogModals/NotifyCartChangedModal';
import VoucherModal from '@/components/Modals/Vouchers';
import iPay88 from '@/components/PaymentForms/iPay88';
import Cash from '@/components/PaymentForms/Cash';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import debounce from 'debounce';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default {
  components: {
    CartProductCard,
    NotifyUpdateModal,
    NotifyCartChangedModal,
    VoucherModal,
    iPay88,
    Cash, 
    ValidationObserver,
    ValidationProvider,
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: null,
    }
  },
  data() {
    return {
      title: 'Checkout',
      version: null,
      hasUpdate: false,
      hasUpdateCustomer: false,
      isCartChanged: false,
      isCartUpdated: false,
      cartChangedType: 'others',
      tableDetails: {
        tableNo: '',
      },
      billingDetails: {
        email: '',
      },
      receiverDetails: {
        name: '',
        contactNo: '',
      },
      minReceivingDate: new Date(),
      receivingDate: null,
      receivingTime: null,
      attrs: [{
        key: 'selected',
        highlight: {
          class: 'vc-selected',
        },
        dates: new Date(),
      }],
      modelConfig: {
        timeAdjust: '00:00:00',
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      availableReceivingTimes: [],
      showVoucher: false,
      selectedVoucherCode: '',
      googleAutocompleteService: null,
      googlePlaceService: null,
      googleSessionToken: null,
      places: [],
      isLoading: false,
      search: null,
      address: null,
      deliveryAddress: {
        formattedAddress: '',
        location: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        lat: '',
        lng: '',
        url: '',
      },
      instructions: null,
      paymentMethod: null,
      error: null,
      guestOrder: null,
    };
  },
  computed: {
    ...mapState('cart', ['cart', 'service']),
    ...mapState('customer', ['customer']),
    ...mapState('config', {
      googleGeoApiKey: (state) => state.googleGeoApiKey,
      distanceMatrixAiKey: (state) => state.distanceMatrixAiKey,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
      offers: (state) => state.offers,
      storeClosed: (state) => state.storeClosed,
      businessHours: (state) => state.businessHours,
      holidays: (state) => state.holidays,
      preparationEst: (state) => state.preparationEst,
      deliveryEst: (state) => state.deliveryEst,
      cutoffTime: (state) => state.cutoffTime,
      ondemand: (state) => state.ondemand,
      acceptPreOrder: (state) => state.preOrder,
      paymentMethods: (state) => state.paymentMethods,
    }),
    ...mapState('product', {
      products: (state) => state.products,
      categories: (state) => state.categories,
      sizes: (state) => state.sizes,
      addonCategories: (state) => state.addonCategories,
      addonItems: (state) => state.addonItems,
    }),
    ...mapState('order', ['order']),
    ...mapGetters('cart', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
      serviceIcons: 'getServiceIcons', 
      serviceCharge: 'getServiceCharge', 
      packagingFee: 'getPackagingFee', 
      discountTotal: 'getDiscountTotal',
      voucherTotal: 'getVoucherTotal',
      taxTotal: 'getTaxTotal',
      rounding: 'getRounding',
      subTotal: 'getSubTotal',
      totalWithTax: 'getTotalWithTax',
      gtagItems: 'getGtagItems',
      tableNo: 'getTableNo',
    }),
    ...mapGetters('customer', {
      customerName: 'getName',
    }),
    ...mapGetters('merchant', {
      disabledDates: 'getDisabledDates',
      isStoreClosed: 'isStoreClosed',
      isMenuIncludeTax: 'isMenuIncludeTax',
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    availableTableNo() {
      return ['', ...this.profile.tables];
    },
    minOrder() {
      if (this.isDelivery) {
        return this.profile.delivery_minimum_order;
      } else if (this.isPickup) {
        return this.profile.pickup_minimum_order;
      } else {
        return 0;
      }
    },
    maxOrder() {
      if (this.isDelivery) {
        return this.profile.delivery_maximum_order;
      } else if (this.isPickup) {
        return this.profile.pickup_maximum_order;
      } else {
        return 0;
      }
    },
    amountDiffToCheckout() {
      return Math.min((this.cart.totals.subTotal - this.minOrder), 0) || (this.maxOrder > 0 ? Math.max(0, (this.cart.totals.subTotal - this.maxOrder)) : 0);
    },
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('merchant', [
      'getMerchantByHostname', 
      'getMerchantBusinessHours',
      'getMerchantHolidays',
      'getMerchantPaymentMethods',
      'getMerchantOffers',
    ]),
    ...mapActions('product', [
      'getProducts',
      'getProductCategories',
      'getProductSizes',
      'getProductAddonCategories',
      'getProductAddonItems',
    ]),
    ...mapActions('place', ['getDistanceMatrix', 'getDeliveryFee', 'getDeliveryDiscount']),
    ...mapActions('order', ['createOrder', 'getOrder']),
    ...mapMutations('cart', [
      'SET_DELIVERY_DETAILS', 
      'SET_DELIVERY_FEE', 
      'SET_DELIVERY_DISCOUNT', 
      'SET_SERVICE',
      'SET_RECEIVER_DATE', 
      'SET_RECEIVER_TIME', 
      'SET_DISCOUNT', 
      'SET_VOUCHER', 
      'REMOVE_VOUCHER', 
      'RESET_CART', 
      'UPDATE_CART_ITEM',
      'REMOVE_CART_ITEM',
    ]),
    ...mapMutations('customer', ['SET_CUSTOMER']),
    ...mapMutations('order', ['SET_ORDER']),
    getServiceLabel() {
      if (this.isDelivery) return 'Delivery';
      if (this.isPickup) return 'Pick Up';
      if (this.isDinein) return 'Dine In';

      return '';
    },
    getBusinessHoursByDate(date) {
      return this.businessHours.filter(
        (x) => x.dayOfWeek == date.day() + 1
      )?.[0];
    },
    isOrderClosed() {
      // Store Closed
      if (this.storeClosed) {
        return true;
      }

      // Accept Pre-order, order not closed
      if (this.acceptPreOrder) {
        return false;
      }

      if (this.holidays.includes(`${dayjs().startOf('d').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`)) {
        return true;
      }

      const businessHoursToday = this.getBusinessHoursByDate(dayjs());
      if (!businessHoursToday) {
        return true;
      }

      const arrPreparationEst = this.preparationEst.split(' ');

      // Order start and end time
      let orderStartAt = dayjs(businessHoursToday.open_am_at, 'HH:mm:ss');
      let orderEndAt = dayjs(
        businessHoursToday.close_pm_at || businessHoursToday.close_am_at,
        'HH:mm:ss'
      );

      if (!['day', 'days', 'week'].includes(arrPreparationEst[1])) {
        orderStartAt = orderStartAt.subtract(
          arrPreparationEst[0],
          arrPreparationEst[1]
        );
        orderEndAt = orderEndAt.subtract(
          arrPreparationEst[0],
          arrPreparationEst[1]
        );
      }

      if (this.isDelivery) {
        const arrDeliveryEst = this.deliveryEst.split(' ');

        orderStartAt = orderStartAt.subtract(
          arrDeliveryEst[0],
          arrDeliveryEst[1]
        );

        if (this.cutoffTime) {
          orderEndAt = dayjs(this.cutoffTime, 'HH:mm:ss');
        } else {
          orderEndAt = orderEndAt.subtract(
            arrDeliveryEst[0],
            arrDeliveryEst[1]
          );
        }
      }

      // Within business hours
      if (dayjs().isBetween(orderStartAt, orderEndAt, null, '[]')) {
        return false;
      } else {
        return true;
      }
    },
    isPreOrder() {
      return !dayjs(this.minReceivingDate).isSame(dayjs(), 'day');
    },
    isValidPhoneNo(phoneNo) {
      return (/^\d{10,13}$/.test(phoneNo));
    },
    async setMinReceivingDate() {
      let minReceivingDate = dayjs(
        this.minReceivingDate.getMinutes() <= 30
          ? dayjs(this.minReceivingDate).format('YYYY-MM-DD HH:30:ss')
          : dayjs(this.minReceivingDate)
              .add(1, 'hour')
              .format('YYYY-MM-DD HH:00:ss'),
        'YYYY-MM-DD HH:mm:ss'
      );

      const businessHoursToday = this.getBusinessHoursByDate(minReceivingDate);
      const arrPreparationEst = this.preparationEst.split(' ');

      // let orderStartAt = null;
      let orderEndAt = null;

      if (businessHoursToday) {
        // Order start and end time
        // orderStartAt = dayjs(businessHoursToday.open_am_at, 'HH:mm:ss');
        orderEndAt = dayjs(
          businessHoursToday.close_pm_at || businessHoursToday.close_am_at,
          'HH:mm:ss'
        );

        if (!['day', 'days', 'week'].includes(arrPreparationEst[1])) {
          // orderStartAt = orderStartAt.subtract(arrPreparationEst[0], arrPreparationEst[1]);
          orderEndAt = orderEndAt.subtract(
            arrPreparationEst[0],
            arrPreparationEst[1]
          );
        }

        if (this.isDelivery) {
          const arrDeliveryEst = this.deliveryEst.split(' ');

          // orderStartAt = orderStartAt.subtract(arrDeliveryEst[0], arrDeliveryEst[1]);

          if (this.cutoffTime) {
            orderEndAt = dayjs(this.cutoffTime, 'HH:mm:ss');
          } else {
            orderEndAt = orderEndAt.subtract(
              arrDeliveryEst[0],
              arrDeliveryEst[1]
            );
          }
        }
      }

      if (
        this.holidays.includes(`${minReceivingDate.startOf('d').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`) ||
        !businessHoursToday ||
        (minReceivingDate.isAfter(orderEndAt) && minReceivingDate.isSame(orderEndAt, 'day'))
      ) {
        minReceivingDate = minReceivingDate.add(1, 'day');
      }

      if (!this.ondemand) {
        minReceivingDate = minReceivingDate.add(1, 'day');

        if (['day', 'days', 'week'].includes(arrPreparationEst[1])) {
          minReceivingDate = minReceivingDate.add(
            arrPreparationEst[0],
            arrPreparationEst[1]
          );
        }
      }

      this.minReceivingDate = minReceivingDate.toDate();

      this.receivingDate = this.minReceivingDate;

      // Set for highlight selected date
      this.attrs[0].dates = this.receivingDate;
    },
    applyVoucher(voucher) {
      if ((this.isDelivery && !voucher.delivery) 
        || (this.isPickup && !voucher.pickup) 
        || (this.isDinein && !voucher.dinein)) {
        this.$gtag.event('apply-voucher-invalid', {
          'event_category': 'experience',
          'event_label': 'Apply Voucher (Invalid)',
          'event_value': voucher.code,
        });

        this.error = `This voucher is invalid for ${this.service}`;
        this.clearError();
        return;
      }

      if (this.cart.totals.subTotal < voucher.min_purchase) {
        this.$gtag.event('apply-voucher-min', {
          'event_category': 'experience',
          'event_label': 'Apply Voucher (Min)',
          'event_value': `${voucher.code} (Min: ${voucher.min_purchase}, Sub Total: ${this.cart.totals.subTotal})`,
        });

        this.error = `Add ${this.$n((voucher.min_purchase - this.cart.totals.subTotal), 'currency', 'en-MY')} more to enjoy this voucher (Min. order ${this.$n(voucher.min_purchase, 'currency', 'en-MY')})`;
        this.clearError();
        return;
      }

      if (voucher.item && !this.cart.products.find(product => product.id === voucher.item?.id)) {
        this.$gtag.event('apply-voucher-item', {
          'event_category': 'experience',
          'event_label': 'Apply Voucher (Item)',
          'event_value': `${voucher.code} (Item not found)`,
        });

        this.error = `Add ${voucher.item?.name} to cart to use this voucher.`;
        this.clearError();
        return;
      }

      this.$gtag.event('apply-voucher', {
        'event_category': 'engagement',
        'event_label': 'Apply Voucher',
        'event_value': voucher.code,
      });

      this.selectedVoucherCode = voucher.code;

      this.SET_VOUCHER(voucher);
    },
    removeVoucher() {
      this.$gtag.event('remove-voucher', {
        'event_category': 'engagement',
        'event_label': 'Remove Voucher',
        'event_value': this.selectedVoucherCode,
      });

      this.selectedVoucherCode = '';
      this.REMOVE_VOUCHER();
    },
    showVoucherModal() {
      this.$gtag.event('show-checkout-voucher', {
        'event_category': 'interaction',
        'event_label': 'Show Voucher (Checkout)',
      });

      if (this.isLoggedIn) {
        this.showVoucher = true;
      } else {
        this.$router.push({ name: 'login', query: { redirectFullPath: '/checkout' } });
      }
    },
    closeVoucher() {
      this.showVoucher = false;
    },
    parseAddressComponent(addressComponent) {
      let arrCity = [];

      for (const component of addressComponent) {
        if (component.types.includes('country')) {
          this.deliveryAddress.country = component.long_name;
        }

        if (component.types.includes('administrative_area_level_1')) {
          this.deliveryAddress.state = component.long_name;
        }

        if (component.types.includes('locality')) {
          arrCity.push(component.long_name);
        }

        if (component.types.includes('sublocality')) {
          arrCity.unshift(component.long_name);
        }

        if (component.types.includes('route')) {
          this.deliveryAddress.street = component.long_name;
        }

        if (component.types.includes('postal_code')) {
          this.deliveryAddress.zipcode = component.long_name;
        }
      }

      this.deliveryAddress.city = arrCity.join(', ');
    },
    async handleCartUpdated() {
      // Check whether can checkout
      const amount = this.amountDiffToCheckout;

      if (amount < 0) {
        this.$gtag.event('update-cart-min', {
          'event_category': 'experience',
          'event_label': 'Update Cart (Min)',
          'event_value': `Min: ${this.minOrder}, Sub Total: ${this.cart.totals.subTotal}`,
        });

        this.error = `Add ${this.$n((amount * -1), 'currency', 'en-MY')} more to checkout (Min. order ${this.$n(this.minOrder, 'currency', 'en-MY')})`;
      }

      if (amount > 0) {
        this.$gtag.event('update-cart-max', {
          'event_category': 'experience',
          'event_label': 'Update Cart (Max)',
          'event_value': `Max: ${this.maxOrder}, Sub Total: ${this.cart.totals.subTotal})`,
        });

        this.error = `Reduce ${this.$n(amount, 'currency', 'en-MY')} to checkout (Max. order ${this.$n(this.maxOrder, 'currency', 'en-MY')})`;
      }
      // End

      // Check offers
      let hasOffer = false;
      for (const offer of this.offers) {
        if (this.cart.totals.subTotal >= offer.min) {
          this.SET_DISCOUNT({
            id: offer.id,
            percentage: offer.percentage,
            min: offer.min,
            amount: +((this.cart.totals.subTotal * offer.percentage / 100).toFixed(2)),
          });
          hasOffer = true;

          break;
        }
      }

      if (!hasOffer) {
          this.SET_DISCOUNT({
            id: '',
            percentage: 0,
            min: 0.0,
            amount: 0.0,
          });
      }

      // Check delivery discount
      await this.getDeliveryDiscount({
        distance: this.cart.delivery.distance / 1000,
        subTotal: this.cart.totals.subTotal,
        merchantId: this.profile.merchant_id,
      });
      // End

      if (this.selectedVoucherCode && this.cart.totals.subTotal < this.cart.voucher.min) {
        this.$gtag.event('update-cart-remove-voucher', {
          'event_category': 'experience',
          'event_label': 'Update Cart (Remove Voucher)',
          'event_value': `Min: ${this.cart.voucher.min}, Sub Total: ${this.cart.totals.subTotal}`,
        });

        this.error = `Add ${this.$n((this.cart.voucher.min - this.cart.totals.subTotal), 'currency', 'en-MY')} more to enjoy this voucher (Min. order ${this.$n(this.cart.voucher.min, 'currency', 'en-MY')})`;

        this.removeVoucher();
      }

      if (this.cart.products?.length === 0) {
        this.RESET_CART();
        this.close();
      }

      if (this.error) {
        this.clearError();
      }
    },
    validateCartTotal() {
      let isValid = true;
      let subTotal = 0;
      
      const products = JSON.parse(localStorage.getItem("cart") || '{}')?.products;
      for (let i=0; i<products?.length; i++) {
        const selectedProduct = this.products.find(product => product.item_id === products[i].id);
        if (!selectedProduct) {
          isValid = false;
          break;
        }

        const selectedProductPrice = selectedProduct.skus;
        const selectedProductMultiOption = JSON.parse(selectedProduct.multi_option || '{}');

        let addonTotal = 0;
        for (let j=0; j<products[i].addons.length; j++) {
          for (let k=0; k<products[i].addons[j].items.length; k++) {
            const selectedItem = this.addonItems.find(addonItem => addonItem.sub_item_id === products[i].addons[j].items[k].id && addonItem.sub_item_name === products[i].addons[j].items[k].name && (selectedProductMultiOption[products[i].addons[j].id][0] !== 'one') === products[i].addons[j].items[k].multiple);
            if (!selectedItem) {
              isValid = false;
              break;
            }

            // Update addon item price
            addonTotal += (+(products[i].addons[j].items[k].quantity || 1) * +selectedItem.price);
          }
        }

        subTotal += (+selectedProductPrice[products[i].size.id] - +selectedProduct.discount + addonTotal) * +products[i].quantity;
      }

      if (this.cart.totals.subTotal.toFixed(2) != subTotal.toFixed(2)) {
        this.$gtag.event('update-cart-wrong-total', {
          'event_category': 'experience',
          'event_label': 'Update Cart (Wrong Total)',
          'event_value': `Verified: ${subTotal}, Sub Total: ${this.cart.totals.subTotal}`,
        });

        this.cartChangedType = 'price';
        this.isCartChanged = true;
        this.isCartUpdated = true;

        isValid = false;
      }

      return isValid;
    },
    updateCart() {
      this.cart.totals.taxableTotal = 0.0;
      this.cart.totals.subTotal = 0.0;

      let notAvailableProducts = 0;
      for (let i=0; i<this.cart.products.length; i++) {
        const selectedProduct = this.products.find(product => product.item_id === this.cart.products[i].id);
        const selectedProductPrice = selectedProduct?.skus;
        const selectedProductMultiOption = JSON.parse(selectedProduct?.multi_option || '{}');
        const hasPrice = Object.hasOwn(selectedProductPrice, this.cart.products[i].size.id) && selectedProductPrice[this.cart.products[i].size.id] !== null && selectedProductPrice[this.cart.products[i].size.id] != undefined;
        if (!selectedProduct || selectedProduct.item_name != this.cart.products[i].name || selectedProduct.not_available != '1' || !hasPrice) {
          notAvailableProducts++;
          this.REMOVE_CART_ITEM({
            index: i,
            id: this.cart.products[i].id,
          });
          continue;
        }

        let addonTotal = 0;
        let notAvailableAddons = 0;
        for (let j=0; j<this.cart.products[i].addons.length; j++) {
          const selectedCategory = this.addonCategories.find(addonCategory => addonCategory.subcat_id === this.cart.products[i].addons[j].id && addonCategory.subcategory_name === this.cart.products[i].addons[j].name);
          if (!selectedCategory) {
            notAvailableAddons++;
            break;
          }

          let notAvailableAddonItems = 0;
          for (let k=0; k<this.cart.products[i].addons[j].items.length; k++) {
            const selectedItem = this.addonItems.find(addonItem => addonItem.sub_item_id === this.cart.products[i].addons[j].items[k].id && addonItem.sub_item_name === this.cart.products[i].addons[j].items[k].name && (selectedProductMultiOption[this.cart.products[i].addons[j].id][0] !== 'one') === this.cart.products[i].addons[j].items[k].multiple);
            if (!selectedItem) {
              notAvailableAddonItems++;
              break;
            }

            // Update addon item price
            this.cart.products[i].addons[j].items[k].price = selectedItem.price;
            addonTotal += (+(this.cart.products[i].addons[j].items[k].quantity || 1) * +this.cart.products[i].addons[j].items[k].price);
          }

          if (notAvailableAddonItems > 0) {
            notAvailableAddons++;
            break;
          }
        }

        if (notAvailableAddons > 0) {
          notAvailableProducts++;
          this.REMOVE_CART_ITEM({
            index: i,
            id: selectedProduct.item_id,
          });
          continue;
        }

        this.UPDATE_CART_ITEM({
          index: i,
          id: selectedProduct.item_id,
          nonTaxable: selectedProduct.non_taxable,
          price: +selectedProductPrice[this.cart.products[i].size.id],
          discount: +selectedProduct.discount,
          addonTotal: +addonTotal.toFixed(2),
        });
      }
      
      if (notAvailableProducts > 0) {
        // alert some items are not available
        this.cartChangedType = 'item';
        this.isCartChanged = true;
      }

      this.isCartUpdated = true;
    },
    closeNotifyCartChanged(type) {
      if (type === 'price') {
        setTimeout(() => window.location.reload(), 700);
      }
    },
    onSubmit: debounce(async function () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          if (this.totalWithTax == 0) {
            this.paymentMethod = this.isDelivery ? 'cod' : 'pyc';
          }

          if ((this.isLoggedIn || !this.isDinein) && (!this.receiverDetails.name || !this.receiverDetails.contactNo)) {
            this.error = 'Receiver Name & Contact No are required';
          } else if ((this.isLoggedIn || !this.isDinein) && !(/^\d{10,13}$/.test(this.receiverDetails.contactNo))) {
            this.error = 'Contact No format is invalid. Please remove any <space>, <plus> or <hyphen>';
          } else if (!this.isDinein && (!this.receivingDate || !this.receivingTime)) {
            this.error = 'Receiving Date & Time are required';
          } else if (this.isDelivery && !this.deliveryAddress.formattedAddress) {
            this.error = 'Delivery address is required';
          } else if (this.isDinein && !this.tableNo && !this.tableDetails.tableNo) {
            this.error = 'Table No. is required';
          } else if (!this.paymentMethod) {
            this.error = 'Payment method is required';
          } else if (!this.isLoggedIn && !this.isDinein && !this.billingDetails.email) {
            this.error = 'Billing email is required';
          }

          this.clearError();

          return;
        }

        // Safety check
        if (this.isDinein && !this.tableNo && !this.tableDetails.tableNo) {
          this.error = 'Table No. is required';
          this.clearError();

          return;
        }

        // Hacking patch
        if (this.isDinein && !this.paymentMethod) {
          this.paymentMethod = 'pyc';
        }

        if (this.selectedVoucherCode && this.cart.totals.subTotal < this.cart.voucher.min) {
          this.$gtag.event('update-cart-remove-voucher', {
            'event_category': 'experience',
            'event_label': 'Update Cart (Remove Voucher)',
            'event_value': `Min: ${this.cart.voucher.min}, Sub Total: ${this.cart.totals.subTotal}`,
          });

          this.error = `Add ${this.$n((this.cart.voucher.min - this.cart.totals.subTotal), 'currency', 'en-MY')} more to enjoy this voucher (Min. order ${this.$n(this.cart.voucher.min, 'currency', 'en-MY')})`;
          this.clearError();

          this.removeVoucher();

          return;
        }

        if (this.isDinein && !this.validateCartTotal()) {
          return;
        }

        // Confirm order
        this.confirmOrder();
      });
    }, 300),
    async confirmOrder() {
      try {
        this.isLoading = true;

        if (this.isLoggedIn && !this.isDelivery) {
          if (this.receiverDetails.name !== this.customerName) {
            this.customer.firstName = this.receiverDetails.name;
            this.customer.lastName = '';
            this.hasUpdateCustomer = true;
          }

          if (this.receiverDetails.contactNo !== this.customer.phoneNo) {
            this.customer.phoneNo = this.receiverDetails.contactNo;
            this.hasUpdateCustomer = true;
          }

          if (this.hasUpdateCustomer) {
            this.SET_CUSTOMER(this.customer);
          }
        }

        const orderId = await this.createOrder({
          uuid: localStorage.uuid,
          merchantId: this.profile.merchant_id,
          service: this.service,
          paymentMethod: this.paymentMethod,
          cart: JSON.stringify(this.cart),
          subTotal: this.cart.totals.subTotal.toFixed(2),
          tax: this.cart.totals.taxRate / 100,
          taxableTotal: this.taxTotal,
          totalWithTax: this.totalWithTax.toFixed(2),
          rounding: this.rounding,
          packagingFeeRate: this.cart.totals.packagingFee,
          packagingFee: this.packagingFee,
          isPackagingFeeIncremental: +(this.cart.totals.packagingIncremental),
          serviceChargeRate: this.cart.totals.serviceChargeRate,
          serviceCharge: this.serviceCharge,
          isMenuIncludeTax: +this.isMenuIncludeTax,
          delivery: {
            date: this.isDinein ? dayjs().format('YYYY-MM-DD') : this.cart.receiver.date,
            time: this.isDinein ? dayjs().format('HH:mm') : this.cart.receiver.time,
            address: this.deliveryAddress,
            originalFee: this.cart.delivery.originalFee,
            fee: this.cart.delivery.fee,
            discount: this.cart.delivery.discount,
            distance: this.cart.delivery.distance,
            duration: this.cart.delivery.duration,
          },
          discount: this.cart.discount,
          voucher: this.cart.voucher,
          voucherTotal: this.voucherTotal,
          receiver: this.receiverDetails,
          billingEmail: (!this.isLoggedIn && !this.isDinein) ? this.billingDetails.email : null,
          instructions: this.instructions,
          tableNo: this.isDinein ? (this.tableNo || this.tableDetails.tableNo) : null,
          isGuestCheckout: +(!this.isLoggedIn),
          hasUpdateCustomer: +(this.hasUpdateCustomer),
        });

        this.$gtag.purchase({
          /** Unique ID for the transaction. */
          transaction_id: orderId,
          /** Value (i.e., revenue) associated with the event */
          value: +this.totalWithTax.toFixed(2),
          /** Tax amount */
          tax: +this.taxTotal,
          /** Shipping cost */
          shipping: +this.cart.delivery?.fee,
          /** The array containing the associated products */
          items: this.gtagItems,
          /** The step (a number) in the checkout process */
          checkout_step: 2,
          /** Checkout option (i.e. selected payment method) */
          checkout_option: this.paymentMethod,
        });

        if (this.isLoggedIn && !this.isDinein) {
          await this.getOrder({ id: orderId });
        } else {
          this.guestOrder = {
            order_id: orderId,
            total_w_tax: this.totalWithTax.toFixed(2),
            email_address: this.billingDetails.email,
            contact_phone: this.receiverDetails.contactNo,
            first_name: this.receiverDetails.name, 
            last_name: '',
            restaurant_name: this.profile.restaurant_name,
          }
        }

        setTimeout(() => this.isLoading = false, 30000);
      } catch (e) {
        console.log(e);
        this.$gtag.event('confirm-order-error', {
          'event_category': 'experience',
          'event_label': 'Confirm Order (Error)',
          'event_value': e.message,
        });

        this.isLoading = false;
        this.error =
          'Server is busy, please try again. If problem persist, please contact us.';

        this.clearError();
      }
    },
    getPaymentId() {
      switch (this.paymentMethod) {
        case 'mpo':
          return '542';
        case 'tng':
          return '538';
        case 'gpo':
          return '523';
        case 'ip8':
          return '2';
        case 'fpx':
          return '16';
        case 'shp':
          return '801';
        case 'bst':
          return '210';
        case 'cod':
          return '9999';
        case 'pyc':
          return '8888';
        default:
          return '';
      }
    },
    getErrorMessage() {
      return `You may place order again if cancelled the payment. 
        Otherwise, it might be something went wrong at payment gateway. Please contact support. Thank You.`;
    },
    isIpay88() {
      return ['ip8', 'gpo', 'mpo', 'tng', 'fpx', 'shp', 'bst'].includes(
        this.paymentMethod
      );
    },
    isCash() {
      return ['cod', 'pyc'].includes(
        this.paymentMethod
      );
    },
    clearError() {
      setTimeout(() => this.error = null, 5000);
    },
    close() {
      this.$router.push({ name: (this.isDinein ? 'dinein' : 'menu'), params: (this.isDinein ? { 'tableNo': (this.tableNo || this.tableDetails.tableNo) } : {}) });
    },
    async checkUpdate() {
      this.version = JSON.parse(await this.getAdminOption({
        key: 'version',
      }));
      const localVersion = JSON.parse(localStorage.version || '{}');

      if (!localVersion.updatedAt || dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))) {
        if (JSON.parse(this.version.reset)) {
          localStorage.removeItem("cart");
        }
        localStorage.version = JSON.stringify(this.version);

        if (dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))) {
          this.hasUpdate = true;
        }
      }
    },
  },
  async created() {
    await this.getAdminOption({
      key: 'google-geo-api-key',
      params: {
        dayOfWeek: new Date().getDay() + 1,
        // merchantId: this.merchant.merchant_id,
      },
    });

    await this.getAdminOption({ key: 'distance-matrix-ai-key' });

    // Load google map api script
    if (!document.getElementById('map-google-api')?.value) {
      let googleMapApiScript = document.createElement('script');
      googleMapApiScript.setAttribute('id', 'map-google-api');
      googleMapApiScript.setAttribute(
        'src',
        'https://maps.googleapis.com/maps/api/js?key=' +
          this.googleGeoApiKey +
          '&libraries=places'
      );
      document.head.appendChild(googleMapApiScript);
    }

    setTimeout(() => {
      const google = window.google;

      this.googleAutocompleteService =
        new google.maps.places.AutocompleteService();
      this.googlePlaceService = new google.maps.places.PlacesService(
        new google.maps.Map(document.createElement('div'))
      );
      this.googleSessionToken =
        new google.maps.places.AutocompleteSessionToken();
    }, 500);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  beforeRouteLeave(to, from, next) {
    if (this.showVoucher) {
      this.showVoucher = false;
      next(false);
    } else {
      next(true);
    }
  },
  async mounted() {
    // Extend dayjs plugin
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(customParseFormat);

    this.SET_ORDER(null);

    if (!this.prevRoute?.name) {
      await this.checkUpdate();
    }

    this.isLoading = true;

    if (!this.profile) {
      // Get merchant profile
      await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);
    }

    if (this.cart.products?.length === 0) {
      this.close();
    }

    if (this.$route.query.statusCode && !this.prevRoute?.name) {
      this.error = this.getErrorMessage();
      this.clearError();
    }

    this.title = `[Checkout] ${this.profile.restaurant_name}`;

    await this.getMerchantOffers(this.profile.merchant_id);

    // Load Business Hours
    await this.getMerchantBusinessHours({
      merchantId: this.profile.merchant_id,
      service: this.service,
      stateId: this.profile.state_id,
    });

    // Store is closed
    if (this.isOrderClosed()) {
      alert('We are temporarily closed !');
      this.$router.push({ name: (this.isDinein ? 'dinein' : 'menu') });
    }

    if (!this.categories) {
      await this.getProductCategories({
        merchantId: this.profile.merchant_id,
      });
    }

    await this.getProducts({
      merchantId: this.profile.merchant_id,
      service: this.service,
    });

    // Get all merchant product sizes
    await this.getProductSizes({
      merchantId: this.profile.merchant_id,
    });

    if (!this.addonCategories) {
      // Get all merchant product addon categories
      await this.getProductAddonCategories({
        merchantId: this.profile.merchant_id,
      });
    }

    // Get all merchant product addon items
    await this.getProductAddonItems({
      merchantId: this.profile.merchant_id,
      service: this.service,
    });

    // Set receiving start date
    await this.setMinReceivingDate();

    // Reset delivery fee
    await this.SET_DELIVERY_FEE(0.0);
    await this.SET_DELIVERY_DISCOUNT(0.0);
    
      // Update cart with latest menu
    this.updateCart();

    await this.getMerchantPaymentMethods({
      merchantId: this.profile.merchant_id,
      service: this.service,
    });
    setTimeout(() => {
      if (this.paymentMethods?.length === 1) this.paymentMethod = this.paymentMethods[0].value;
      this.isLoading = false;
    }, 100);

    if (!this.isDinein || this.isLoggedIn) {
      this.receiverDetails.name = this.customerName.trim();
      this.receiverDetails.contactNo = this.customer?.phoneNo
    }
    this.selectedVoucherCode = this.cart.voucher.code;
  },
  watch: {
    receivingDate: function (val) {
      this.$gtag.event('select-receiving-date', {
        'event_category': 'interaction',
        'event_label': 'Select Receiving Date (Checkout)',
        'event_value': val,
      });

      // Set for highlight selected date
      this.attrs[0].dates = this.receivingDate;

      const newReceivingDate = dayjs(val).startOf('d');
      const businessHours = this.getBusinessHoursByDate(newReceivingDate);

      if (
        !newReceivingDate.isSame(dayjs(), 'day') &&
        (!businessHours || this.holidays.includes(`${newReceivingDate.startOf('d').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`))
      ) {
        // Recursive trigger watch here
        this.minReceivingDate = newReceivingDate.add(1, 'day').toDate();
        this.receivingDate = this.minReceivingDate;
      } else {
        // Clear previous time selection
        this.availableReceivingTimes.length = 0;

        // Receiving Date is found, set Receiving Time options here
        let isStart = false;
        let isBreak = false;
        let isEnd = false;

        const arrPreparationEst = this.preparationEst.split(' ');

        let nowTime = dayjs().add(arrPreparationEst[0], arrPreparationEst[1]);
        if (this.isDelivery) {
          const arrDeliveryEst = this.deliveryEst.split(' ');
          nowTime = nowTime.add(arrDeliveryEst[0], arrDeliveryEst[1]);
        }

        const openAmAt = dayjs(businessHours.open_am_at, 'HH:mm');
        const closeAmAt = dayjs(businessHours.close_am_at, 'HH:mm');
        const openPmAt = dayjs(businessHours.open_pm_at, 'HH:mm');
        const closePmAt = dayjs(businessHours.close_pm_at, 'HH:mm');

        const minTime = (newReceivingDate.isSame(dayjs(), 'day') && nowTime.isAfter(openAmAt)) ? nowTime : openAmAt;
        for (let min = 0; min < 24 * 60 && !isEnd; min += 30) {
          // 24 * 60 mins per day
          const newReceivingTime = dayjs().startOf('d').add(min, 'minute');

          // Check whether order start
          if (!isStart && (newReceivingTime.isSameOrAfter(minTime) || newReceivingTime.diff(minTime, 'minutes') >= -5)) {
            this.receivingTime = newReceivingTime.format('HH:mm');
            isStart = true;
          }

          // Check whether order break or end
          if (
            newReceivingTime.isAfter(closeAmAt) || 
            newReceivingTime.diff(closeAmAt, 'minutes') > 5
          ) {
            if (businessHours.open_pm_at && businessHours.close_pm_at) {
              isBreak = true;
            } else {
              isEnd = true;
            }
          }

          // Check whether order break is over
          if (
            businessHours.open_pm_at && 
            isBreak &&
            newReceivingTime.isSameOrAfter(openPmAt)
          ) {
            isBreak = false;
          }

          if (
            businessHours.close_pm_at && 
            (newReceivingTime.isAfter(closePmAt) || newReceivingTime.diff(closePmAt, 'minutes') > 5)
          ) {
            isEnd = true;
          }

          if (isStart && !isBreak && !isEnd) {
            this.availableReceivingTimes.push({
              label: newReceivingTime.format('hh:mm A'),
              value: newReceivingTime.format('HH:mm'),
            });
          }
        }
      }

      this.SET_RECEIVER_DATE(dayjs(this.receivingDate).format('YYYY-MM-DD'));
    },
    receivingTime: debounce(async function (val) {
      this.$gtag.event('select-receiving-time', {
        'event_category': 'interaction',
        'event_label': 'Select Receiving Time (Checkout)',
        'event_value': val,
      });

      this.SET_RECEIVER_TIME(val);

      // Recheck Delivery Fee due to Surcharge on delivery time selected
      if (this.isDelivery && this.cart.delivery.distance > 0) {
        await this.getDeliveryFee({
          time: val,
          distance: this.cart.delivery.distance / 1000,
          stateId: this.profile.state_id,
          merchantId: this.profile.merchant_id,
        });

        await this.getDeliveryDiscount({
          distance: this.cart.delivery.distance / 1000,
          subTotal: this.cart.totals.subTotal,
          merchantId: this.profile.merchant_id,
        });
      }
    }, 300),
    search: debounce(async function (val) {
      if (this.isLoading) return;

      this.isLoading = true;

      if (!val) {
        this.isLoading = false;
        return;
      }

      this.$gtag.event('search-location', {
        'event_category': 'interaction',
        'event_label': 'Search Location (Checkout)',
        'event_value': val,
      });

      this.places = null;
      this.address = null;
      this.googleAutocompleteService.getPlacePredictions(
        {
          input: val,
        },
        (predictions, status) => {
          const google = window.google;
          if (
            status == google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            this.places = predictions;
          }

          this.isLoading = false;
        }
      );
    }, 700),
    address: debounce(function (place) {
      this.$gtag.event('select-location', {
        'event_category': 'interaction',
        'event_label': 'Select Location (Checkout)',
        'event_value': place,
      });

      this.googlePlaceService.getDetails(
        {
          placeId: place.place_id,
          sessionToken: this.googleSessionToken,
        },
        async (place, status) => {
          const google = window.google;

          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            place &&
            place.geometry &&
            place.geometry.location
          ) {
            this.isLoading = true;

            let distanceMatrix = await this.getDistanceMatrix({
              pickupLatLng: `${this.profile.latitude},${this.profile.lontitude}`,
              deliveryLatLng: place.geometry.location.toString(),
              key: this.distanceMatrixAiKey,
              provider: 'distancematrixai',
            });

            this.isLoading = false;

            if (
              !distanceMatrix ||
              !distanceMatrix.rows ||
              !distanceMatrix.rows.length ||
              !distanceMatrix.rows[0].elements ||
              !distanceMatrix.rows[0].elements.length ||
              distanceMatrix.rows[0].elements[0].status !== 'OK'
            ) {
              this.isLoading = true;

              distanceMatrix = await this.getDistanceMatrix({
                pickupLatLng: `${this.profile.latitude},${this.profile.lontitude}`,
                deliveryLatLng: `${place.geometry.location.lat()},${place.geometry.location.lng()}`,
                key: this.googleGeoApiKey,
                provider: 'google',
              });

              this.isLoading = false;

              if (
                !distanceMatrix ||
                !distanceMatrix.rows ||
                !distanceMatrix.rows.length ||
                !distanceMatrix.rows[0].elements ||
                !distanceMatrix.rows[0].elements.length ||
                distanceMatrix.rows[0].elements[0].status !== 'OK'
              ) {
                this.address = '';
                this.error = 'Distance error!';
                return;
              }
            }

            if (
              (+this.profile.options.merchant_delivery_miles > 0) && 
              (distanceMatrix.rows[0].elements[0].distance.value >
              this.profile.options.merchant_delivery_miles * 1000)
            ) {
              this.address = '';
              this.error = 'Out of coverage!';
              this.clearError();
              return;
            }

            // Set delivery address details
            this.deliveryAddress.formattedAddress = this.address.description;
            this.deliveryAddress.location = place.name;
            this.deliveryAddress.lat = place.geometry.location.lat();
            this.deliveryAddress.lng = place.geometry.location.lng();
            this.deliveryAddress.url = place.url;
            this.parseAddressComponent(place.address_components);

            this.SET_DELIVERY_DETAILS({
              distance: distanceMatrix.rows[0].elements[0].distance.value,
              duration: distanceMatrix.rows[0].elements[0].duration.value,
            });

            await this.getDeliveryFee({
              time: this.receivingTime,
              distance:
                distanceMatrix.rows[0].elements[0].distance.value / 1000,
              stateId: this.profile.state_id,
              merchantId: this.profile.merchant_id,
            });

            await this.getDeliveryDiscount({
              distance:
                distanceMatrix.rows[0].elements[0].distance.value / 1000,
              subTotal: this.cart.totals.subTotal,
              merchantId: this.profile.merchant_id,
            });
          }
        }
      );
    }, 300),
    totalWithTax: debounce(function (newVal, oldVal) {
      if (newVal === 0.0) {
        this.paymentMethod = this.isDelivery ? 'cod' : 'pyc';
      } else if (oldVal === 0.0) {
        this.paymentMethod = null;
      }
    }, 100),
  },
};
</script>

<style scoped>
.no-scrolling {
  overflow: hidden !important;
  height: 100vh;
}
.v-toolbar {
  box-shadow: 0px 2px 4px -1px rgb(255 99 71 / 20%),
    0px 4px 5px 0px rgb(255 99 71 / 14%), 0px 1px 10px 0px rgb(255 99 71 / 12%) !important;
}
.receiver-details {
  font-size: 14px;
  font-weight: 500;
}
.receiver-details .v-input__icon--append i {
  color: #cccccc;
}
.brackettext {
  font-size: 12px;
  font-weight: 400;
}
.receiver-details label,
.receiver-details h5,
.checkoutviewCosting h6 {
  font-weight: 600;
}
.receiver-details
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #ffffff6b !important;
}
.summary-order-total > .row {
  margin-top: 4px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.summary-order-total > .row > .col {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.summary-order-total > .row > .col:last-child {
  text-align: right;
}
.summary-voucher-code {
  color: #0288D1;
  font-weight: 600;
}
.term-conditions {
  margin-bottom: 56px;
}
.primarytxt {
  color: #FF6347;
}
button {
  outline: none !important;
}
.receiver-details label,
.receiver-details textarea,
.receiver-details input,
.receiver-details .v-select__slot,
.receiver-details .checkoutbilling h6 {
  font-size: 13px;
}
.receiver-details h4 {
  font-size: 18px;
  font-weight: 600;
}
.alert {
  position: fixed;
  z-index: 999;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
}
.billing-hint, .table-no-hint {
  font-size: 10px;
  font-weight: 600;
}
@media screen and (max-width: 375px) {
  .sm-label {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1023px) {
  .sheet-spacer {
    height: 48px;
  }
}
@media screen and (min-width: 1024px) {
  .alert {
    width: 374px !important;
  }
  .sheet-spacer {
    display: none;
  }
}
</style>
