import loyaltyApi from '../../api/loyalty';
const state = {
  reward: null,
  rewards: [],
};

const mutations = {
  SET_REWARD(state, reward) {
    state.reward = reward;
  },
  SET_REWARDS(state, rewards) {
    state.rewards = rewards;
  },
};

const actions = {
  async create(context, data) {
    const res = await loyaltyApi.create(context.rootState.auth.token, data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }
    return res.data;
  },
  async getLoyaltyReward(context, { id, query }) {
    const res = await loyaltyApi.findReward(id, query);
    if (res?.status != 200) {
      throw Error(res.data.message);
    }
    context.commit('SET_REWARD', res?.data);
    return res?.data;
  },
  async getLoyaltyRewards(context, query) {
    let rewards = (await loyaltyApi.findAllRewards(query))?.data;

    context.commit('SET_REWARDS', rewards);
    return rewards;
  },
};

const getters = {
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
