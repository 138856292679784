import api from './index';

const url = 'loyalty';

export default {
  async create(token, data) {
    return api.post(`${url}`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
  async findAllRewards(query) {
    return api.get(`${url}/reward`, {
      params: query,
    });
  },
  async findReward(id, query) {
    return api.get(`${url}/reward/${id}`, {
      params: query,
    });
  },
};
