<script>
export default {
  props: ['points', 'image-height', 'margin-top', 'route-name'],
  data() {
    return {
      show: true,
      cdnUrl: process.env.VUE_APP_CDN_URL,
    }
  },
  methods: {
    onBtnClicked() {
      if (this.routeName) {
        this.$router.push({ name: this.routeName }).catch(() => {});
      } else {
        this.show = false;
      }
    },
  }
};
</script>
<template>
  <v-dialog v-model="show" :content-class="`margin-top-${marginTop}`" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="font-weight-6 white--text justify-center">
        <v-row class="text-center m-0 p-0">
          <v-col class="m-0 p-0" cols="12">Hurray, you earned</v-col>
          <v-col class="h4 tertiary--text font-weight-bold m-0 p-0" cols="12">{{  points }} points</v-col>
        </v-row>
      </v-card-title>
      <v-img
        :height="imageHeight"
        :src="`${cdnUrl}/upload/1335/news/news-smiling-box.jpg`"
      ></v-img>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="primary--text font-weight-6 px-3 my-1"
          plain
          @click="onBtnClicked"
        >
          Love it!
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content /deep/ .margin-top-20 {
  margin-top: 20vh !important;
}
.v-card {
  background-color: var(--v-primary);
}
</style>
