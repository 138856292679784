import clientsApi from '../../api/clients';

const state = {
  client: localStorage.customer ? JSON.parse(localStorage.customer) : null,
};

const mutations = {
  SET_CLIENT(state, client) {
    if (client) {
      localStorage.customer = JSON.stringify(client);
      state.client = client;

      const iframe = document.getElementById('sso').contentWindow;
      iframe.postMessage({
        action: 'update-customer',
        value: client,
      }, '*');
    } else {
      localStorage.removeItem('customer');
      state.client = null;
    }
  },
};

const actions = {
  async create(context, data) {
    const res = await clientsApi.create(data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }

    context.commit('auth/SET_TOKEN', res.data.token, { root: true });
    context.commit('SET_CLIENT', res.data.data);

    const iframe = document.getElementById('sso').contentWindow;
    iframe.postMessage({
      action: 'login',
      value: {
        token: res.data.token,
        customer: res.data.data,
      },
    }, '*');

    return res.data.data;
  },
};

const getters = {
  getName(state) {
    return (state.client ? ((state.client?.lastName || '') + ' ' + state.client?.firstName) : '')?.trim();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
