<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: ['fixed'],
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('cart', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
    }),
  },
  methods: {
    toHome() {
      if (this.isDinein) {
        if (this.$cookies.get('table-no')) {
          return `/dinein/${this.$cookies.get('table-no')}`;
        } else {
          return `/scan`;
        }
      } else {
        return `/menu`;
      }
    },
    getWhatsAppUrl() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? 'https://api.whatsapp.com/send?phone='
        : 'https://web.whatsapp.com/send?phone=';
    },
  },
};
</script>
<template>
  <v-bottom-navigation
    background-color="white"
    class="bottom-nav-home overflow-hidden px-4 py-2"
    color="primary"
    :fixed="fixed"
    hide-on-scroll
    height="auto"
  >
    <v-btn class="text-decoration-none ml-4" to="/">
      <span class="bottom-nav-text text-caption dark--text font-weight-bold my-1">Home</span>
      <v-icon color="primary" size="22">mdi-home-circle-outline</v-icon>
    </v-btn>

    <v-btn class="text-decoration-none" to="/member">
      <span class="bottom-nav-text text-caption dark--text font-weight-bold my-1">Member</span>
      <v-icon color="primary" size="22">mdi-crown-outline</v-icon>
    </v-btn>

    <v-btn class="text-decoration-none">
      <span class="bottom-nav-text text-caption dark--text font-weight-bold my-1">Install</span>
      <v-icon color="white" class="p-2 rounded-circle" size="26">mdi-cellphone-arrow-down-variant</v-icon>
    </v-btn>

    <v-btn class="text-decoration-none" to="/rewards">
      <span class="bottom-nav-text text-caption dark--text font-weight-bold my-1">Rewards</span>
      <v-icon color="primary" size="22">mdi-gift-outline</v-icon>
    </v-btn>

    <v-btn class="text-decoration-none mr-4" to="/profile">
      <span class="bottom-nav-text text-caption dark--text font-weight-bold my-1">Profile</span>
      <v-icon color="primary" size="22">mdi-account-circle-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<style scoped>
.bottom-nav-home {
  box-shadow: none;
}
.v-btn--active /deep/ .bottom-nav-text {
  color: var(--v-buttonBackground) !important;
}

.v-btn--active /deep/ i {
  color: var(--v-tertiary) !important;
}

.v-btn /deep/ i.rounded-circle {
  background-color: var(--v-primary) !important;
}

.v-bottom-navigation .v-btn {
  min-width: 70px !important;
}

</style>
