<script>
import BottomNav from '@/components/Custom/dayonedayone/BottomNav'
import NotifyUpdateModal from '@/components/DialogModals/NotifyUpdateModal';
import CentralLoader from '@/components/Loaders/CentralLoader';
import DeliveryProviderBottomSheet from '@/components/BottomSheets/DeliveryProviders';
import BranchModal from '@/components/Modals/Branches';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  props: ['installable'],
  components: {
    BottomNav,
    NotifyUpdateModal,
    CentralLoader,
    DeliveryProviderBottomSheet,
    BranchModal,
  },
  metaInfo() {
    return {
      title: `${this.profile.restaurant_name}`,
      titleTemplate: null,
      meta: [
        { name: 'og:url', property: 'og:url', content: `https://${this.profile.restaurant_hostname}` },
        { name: 'og:site_name', property: 'og:site_name', content: this.profile.restaurant_name },
        { name: 'og:title', property: 'og:title', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:description', property: 'og:description', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:image', property: 'og:image', content: this.merchantLogo },
        { name: 'og:type', property: 'og:type', content: 'website' },
        { name: 'og:updated_time', property: 'og:updated_time', content: Date.now() },
      ],
    }
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      version: null,
      isLoading: false,
      hasUpdate: false,
      title: 'Day One Day One',
      serviceRoutes: {
        menubook: 'menubook',
        delivery: 'menu',
        pickup: 'menu',
        dinein: 'scan',
        reservation: 'reserve',
      },
      showDeliveryProvider: false,
      showBranch: false,
      alertMessage: {
        'invalid-voucher': 'Voucher is invalid or expired!',
      }
    };
  },
  computed: {
    ...mapState('loyalty', {
      rewards: (state) => state.rewards,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
      reviews: (state) => state.reviews,
      storeClosed: (state) => state.storeClosed,
      ondemand: (state) => state.ondemand,
      acceptPreOrder: (state) => state.preOrder,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('client', {
      name: 'getName',
    }),
    ...mapGetters('merchant', {
      rating: ['getRating'],
      isTodayHoliday: ['isTodayHoliday'],
      hasTableBooking: ['hasTableBooking'],
      hasBranch: ['hasBranch'],
    }),
    appBarBackground() {
      return this.profile.photo_landing_bg ? `${this.cdnUrl}/${this.profile.photo_landing_bg}` : '';
    },
    landingBackground: function() {
      return this.profile.photo_landing_bg ? {
        backgroundImage: `url(${this.cdnUrl}/${this.profile.photo_landing_bg})`
      } : null;
    },
    merchantLogo() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
    hasLinks: function () {
      return Object.values(this.profile?.links || {})?.some(link => !!link);
    }
  },
  methods: {
    ...mapActions('customer', ['claimVoucher']),
    ...mapActions('loyalty', ['getLoyaltyRewards']),
    ...mapActions('merchant', [
      'getMerchantByHostname',
    ]),
    ...mapMutations('cart', ['SET_SERVICE']),
    hasService(value) {
      return this.profile?.service.find(s => s.value === value);
    },
    selectService(label, value) {
      this.$gtag.event('select-service', {
        'event_category': 'navigation',
        'event_label': label,
        'event_value': value,
      });

      if (value !== 'menubook') {
        this.SET_SERVICE(value);
      }

      if (value === 'delivery' && this.hasLinks) {
        this.showDeliveryProvider = true;
      } else {
        this.$router.push({name: this.serviceRoutes[value]}).catch(() => {});
      }
    },
    scan() {
      this.$gtag.event('scan', {
        'event_category': 'interaction',
        'event_label': 'Scan',
      });
      this.$router.push({name: 'scan'}).catch(() => {});
    },
    install() {
      this.$emit('install', 'home');
    },
    async autoClaimVouchers() {
      const unclaimedVouchers = JSON.parse(localStorage.vouchers || '[]');
      try {
        this.isLoading = true;

        await Promise.all(unclaimedVouchers.map(v => this.claimVoucher({
          voucherId: v.id,
          merchantId: this.profile.merchant_id,
        })));
      } finally {
        localStorage.removeItem('vouchers');
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);

    if (this.$route.query?.alert) {
      alert(this.alertMessage[this.$route.query?.alert]);
    }

    if (localStorage.token) {
      this.autoClaimVouchers();
    }
  },
  async created() {
    try {
      this.isLoading = true;

      if (!this.profile) {
        // Get merchant profile
        await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);
      }

      // Load Loyalty Rewards
      this.getLoyaltyRewards({
        merchantId: this.profile.merchant_id,
      })
      
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  },
};
</script>
<template>
  <v-card class="home-sec" elevation="0">
    <v-img
      class="home-banner rounded-xl"
      height="25vh"
      :src="appBarBackground"
    ></v-img>
    <CentralLoader v-if="isLoading || !profile" color="white"></CentralLoader>
    <v-btn
      color="tertiary"
      class="btn-join-member text-body-2 white--text font-weight-6 rounded-lg p-4 mx-2"
      depressed
      @click="$router.push({ name: 'dayonedayone-signup' }).catch(() => {});"
      v-if="!isLoggedIn"
    >
      Join Member Now
    </v-btn>
    <div v-else>
      <v-card class="loyalty-profile rounded-lg p-0" elevation="2">
        <div class="row m-0">
          <div class="col-3 d-flex justify-center align-items-center p-0">
            <v-icon x-large class="m-0" color="success" style="font-size:60px;">mdi-crown-outline</v-icon>
          </div>
          <div class="col-9 d-flex justify-center align-items-center p-0">
            <div class="row mt-1 ml-1 mr-2 py-2">
              <div class="col-12 font-weight-6 text-body-1 text-truncate primary--text p-0">{{ name }}</div>
              <div class="col-12 font-weight-6 text-body-2 primary--text p-0">
                <v-icon class="mr-1 mb-1" color="tertiary">mdi-star-circle</v-icon>100 pts
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <div class="py-2"></div>
    </div>
    <div class="container pb-0">
      <div class="row">
        <div class="col-12 p-0">
          <v-sheet 
            ref="menu-service-sheet"
            class="menu-service-sheet mb-2 mx-auto"
            style="margin-top: -25px"
            elevation="0"
          >
            <v-slide-group 
              mobile-breakpoint="1024"
              show-arrows="desktop"
              class="service-slider pt-3 pb-2"
            >
              <v-slide-item
                key="e-menu"
                class="service-slider-item text-body-2"
                v-if="profile.options.merchant_photo_menubook"
              >
                <div class="d-flex flex-column justify-center text-center" @click="selectService('Menubook', 'menubook')">
                  <div class="service-slider-item-btn-wrapper rounded-lg py-4 mx-2">
                    <v-btn
                      color="secondary"
                      class="service-slider-item-btn primary--text font-weight-6"
                      depressed
                    >
                      <v-icon size="24" x-large>mdi-clipboard-list</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-caption font-weight-6 mt-1">e-Menu</div>
                </div>
              </v-slide-item>
              <v-slide-item
                class="service-slider-item text-body-2"
                v-if="hasService('delivery')"
              >
                <div class="d-flex flex-column justify-center text-center" @click="selectService('Delivery', 'delivery')">
                  <div class="service-slider-item-btn-wrapper rounded-lg py-4 mx-2">
                    <v-btn
                      color="secondary"
                      class="service-slider-item-btn primary--text font-weight-6"
                      depressed
                    >
                      <v-icon size="24" x-large>mdi-moped-electric</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-caption font-weight-6 mt-1">Delivery</div>
                </div>
              </v-slide-item>
              <v-slide-item
                class="service-slider-item text-body-2"
                v-if="hasService('pickup')"
              >
                <div class="d-flex flex-column justify-center text-center" @click="selectService('Pickup', 'pickup')">
                  <div class="service-slider-item-btn-wrapper rounded-lg py-4 mx-2">
                    <v-btn
                      color="secondary"
                      class="service-slider-item-btn primary--text font-weight-6"
                      depressed
                    >
                      <v-icon size="24" x-large>mdi-food-takeout-box</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-caption font-weight-6 mt-1">Pickup</div>
                </div>
              </v-slide-item>
              <v-slide-item
                class="service-slider-item text-body-2"
                v-if="hasService('dinein')"
              >
                <div class="d-flex flex-column justify-center text-center" @click="selectService('Dine In', 'dinein')">
                  <div class="service-slider-item-btn-wrapper rounded-lg py-4 mx-2">
                    <v-btn
                      color="secondary"
                      class="service-slider-item-btn primary--text font-weight-6"
                      depressed
                    >
                      <v-icon size="24" x-large>mdi-food</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-caption font-weight-6 mt-1">Dine In</div>
                </div>
              </v-slide-item>
              <v-slide-item
                class="service-slider-item text-body-2"
                v-if="hasBranch"
              >
                <div class="d-flex flex-column justify-center text-center" @click="showBranch = true">
                  <div class="service-slider-item-btn-wrapper rounded-lg py-4 mx-2">
                    <v-btn
                      color="secondary"
                      class="service-slider-item-btn primary--text font-weight-6"
                      depressed
                    >
                      <v-icon size="24" x-large>mdi-store-marker</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-caption font-weight-6 mt-1">Outlets</div>
                </div>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 p-0">
          <v-sheet 
            ref="reward-sheet"
            class="reward-sheet pt-2 pb-2 mx-auto"
            elevation="0"
          >
            <div class="h6 primary--text font-weight-bold ml-6 mb-0 pt-3">Get Rewards <v-icon class="primary--text">mdi-arrow-right-thin</v-icon></div>
            <v-slide-group 
              mobile-breakpoint="1024"
              show-arrows="desktop"
              class="reward-slider p-3"
            >
              <v-slide-item
                class="reward-slider-item text-body-2 mx-2"
                :key="`reward-item-${reward.id}`"
                v-for="reward in rewards"
              >
                <v-card class="text-left overflow-hidden" elevation="0">
                  <v-img class="cover-photo" :src="`${cdnUrl}/${reward.cover}`">
                  </v-img>
                  <v-card-title class="subtitle-2 break-word p-2"
                    ><b>
                      {{ reward.code }}
                    </b></v-card-title
                  >
                  <v-card-actions class="p-2 pt-0">
                    <div
                      class="
                        subtitle-2
                        d-flex
                        align-items-center
                        justify-content-center
                        font-weight-600
                      "
                      style="letter-spacing: normal; height: 36px"
                    >
                      <div class="d-flex flex-column">
                        <div
                          class="text--disabled caption font-weight-600"
                          v-if="reward.discount"
                        >
                          <strike>{{ reward.points }}</strike>
                        </div>

                        <div>
                          {{ reward.points }} pts
                        </div>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <v-sheet 
            ref="promotion-sheet"
            class="promotion-sheet pb-4 mb-0 mx-auto"
            elevation="0"
          >
            <div class="h6 primary--text font-weight-bold ml-6 mb-0 pt-3">Get Promotions <v-icon class="primary--text">mdi-arrow-right-thin</v-icon></div>
            <v-slide-group 
              mobile-breakpoint="1024"
              show-arrows="desktop"
              class="promotion-slider p-3"
            >
              <v-slide-item
                class="promotion-slider-item text-body-2 mx-2"
              >
                <v-img :src="`${cdnUrl}/upload/1335/news/news-like-comment-share.jpg`"></v-img>
              </v-slide-item>
              <v-slide-item
                class="promotion-slider-item text-body-2 mx-2"
              >
                <v-img :src="`${cdnUrl}/upload/1335/news/news-limited-time-dessert.jpg`"></v-img>
              </v-slide-item>
              <v-slide-item
                class="promotion-slider-item text-body-2 mx-2"
              >
                <v-img :src="`${cdnUrl}/upload/1335/news/news-mother-day.jpg`"></v-img>
              </v-slide-item>
              <v-slide-item
                class="promotion-slider-item text-body-2 mx-2"
              >
                <v-img :src="`${cdnUrl}/upload/1335/news/news-smiling-box.jpg`"></v-img>
              </v-slide-item>
              <v-slide-item
                class="promotion-slider-item text-body-2 mx-2"
              >
                <v-img :src="`${cdnUrl}/upload/1335/news/news-puchong-opening.jpg`"></v-img>
              </v-slide-item>
              <v-slide-item
                class="promotion-slider-item text-body-2 mx-2"
              >
                <v-img :src="`${cdnUrl}/upload/1335/news/news-tntco.jpg`"></v-img>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </div>
      </div>
    </div>
    <v-divider class="footer-divider m-0 p-0"></v-divider>
    <BottomNav :fixed="true"/>
    <notify-update-modal :version="version" v-if="version && hasUpdate"></notify-update-modal>
    <delivery-provider-bottom-sheet :visible="showDeliveryProvider" v-on:close-delivery-provider="showDeliveryProvider = false"></delivery-provider-bottom-sheet>
    <branch-modal :visible="showBranch" v-on:close-branch="showBranch = false"></branch-modal>
  </v-card>
</template>
<style scoped>
.home-sec {
  padding-bottom: 80px;
}

.home-banner {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.btn-join-member {
  position: absolute;
  top: calc(25vh - 25px);
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
}

.loyalty-profile {
  width: 80%;
  position: absolute;
  top: calc(25vh - 35px);
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
}

.container {
  margin-top: 40px;
}

.reward-sheet, .promotion-sheet {
  background-color: var(--v-secondary);
}

.service-slider, .reward-slider, .promotion-slider {
  width: 100%;
}

.service-slider /deep/ .v-slide-group__wrapper, 
.reward-slider /deep/ .v-slide-group__wrapper, 
.promotion-slider /deep/ .v-slide-group__wrapper {
  touch-action: pan-y !important;
  scroll-behavior: smooth !important;
  scroll-snap-type: x mandatory !important;
}

.service-slider /deep/ .v-slide-group__content {
  justify-content: space-evenly;
}

.service-slider-item-btn-wrapper {
  background-color: var(--v-secondary);
}

.service-slider-item-btn {
  width: calc(25% - 16px) !important;
  min-width: calc(25% - 16px) !important;
}

.promotion-slider /deep/ .v-slide-group__content, .reward-slider /deep/ .v-slide-group__content {
  /* justify-content: space-evenly; */
}

.promotion-slider-item.v-image {
  width: 200px !important;
  height: 120px !important;
  border-radius: 16px;
}

.reward-slider-item {
  height: 100%;
  border-radius: 16px;
}

.reward-slider-item .v-btn__content {
  justify-content: flex-start;
  text-align: left;
}

.reward-slider-item .cover-photo {
  width: 120px !important;
  height: 120px !important;
  object-fit: cover;
}

.footer-divider {
  background-color: rgb(249, 244, 236);
}

@media screen and (min-width: 320px) {
  .service-option {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 360px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .service-option img, .service-option .v-icon {
    width: 18px;
    height: 18px;
  }
}
@media screen and (min-width: 375px) {
  .service-option {
    padding-bottom: 14px;
    padding-top: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (height: 667px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .service-slider, .promotion-slider {
    padding: 0 16px;
  }
}
@media screen and (min-width: 1024px) {
  .loyalty-profile {
    width: 331px;
  }
}
</style>
