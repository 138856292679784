import Vue from 'vue';
import VueX from 'vuex';
import auth from '@/store/modules/auth';
import cart from '@/store/modules/cart';
import client from '@/store/modules/client';
import config from '@/store/modules/config';
import customer from '@/store/modules/customer';
import loyalty from '@/store/modules/loyalty';
import merchant from '@/store/modules/merchant';
import notification from '@/store/modules/notification';
import payment from '@/store/modules/payment';
import place from '@/store/modules/place';
import product from '@/store/modules/product';
import order from '@/store/modules/order';
import reservation from '@/store/modules/reservation';

Vue.use(VueX);

export const store = new VueX.Store({
  modules: {
    auth,
    cart,
    client,
    config,
    customer,
    loyalty,
    merchant,
    notification,
    payment,
    place,
    product,
    order,
    reservation,
  },
});
